export default {
    home: {
        tabList: {
            explore: 'Explore',
            all: 'All',
            music: 'Music',
            fitness: 'Fitness',
            anime: 'Anime',
            movies: 'Movies',
            watched: 'Watched',
            basketball: 'Basketball',
            tvShows: 'TV Shows',
            comedy: 'Comedy'
        },
        left: {
            switchMode: 'Switch Mode',
            desc: 'Over 30,000 movies await your viewing pleasure, free to watch online with no ads to interrupt the excitement! Join the high-definition cinematic feast now!',
            tg: 'Contact Us',
            settings: 'Settings',
            language: 'Language',
            profile: 'Profile',
            logout: 'Logout',
            upload: 'Upload',
            login: 'Login',
            loginMsg: 'Don\'t have account?',
            username: 'username',
            password: 'password',
            signIn: 'Sign in',
            signUp: 'Sign up',
            register: {
                title: 'Register',
                username: 'username or email address',
                password: 'no less than 6 characters',
                show: 'SHOW'
            }
        },
        right: {
            searchPlaceholder: 'Please enter the content.',
            search: 'Search',
            hotTags: 'Hot Tags',
            tags: {
                drama: 'Drama',
                horror: 'Horror',
                suspense: 'Suspense',
                action: 'Action',
                comedy: 'Comedy',
            },
            hotSearch: 'Hot Search'
        },
        popup: {
            p1: 'Not interested',
            p2: 'Report this video',
            p3: 'Add to playlist'
        }
    },
    channel: {
        fans: 'fans',
        views: 'views',
        danmu: 'danmu',
        danmuPlaceholder: 'Send a danmu',
        danmuSubmit: 'Send',
        follow: 'Follow',
        following: 'Following',
        relational: 'Relational',
        comments: 'Comments',
        commentPlaceholder: 'add comment ...',
        commentSubmit: 'Submit',
        commentTip: 'No comments yet'
    },
    profile: {
        posts: 'Posts',
        followers: 'Followers',
        following: 'Following',
        viewProfile: 'View Profile'
    }
};
