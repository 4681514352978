export default {
    home: {
        tabList: {
            explore: '探索',
            all: '全部',
            music: '音乐',
            fitness: '健身',
            anime: '动漫',
            movies: '电影',
            watched: '已看',
            basketball: '篮球',
            tvShows: '电视剧',
            comedy: '搞笑'
        },
        left: {
            switchMode: '切换模式',
            desc: '超过3万部电影火热上线，惊喜不断！零广告干扰，免费畅享高清在线观影盛宴，等你来狂欢！',
            tg: '联系我们',
            settings: '设置',
            language: '语言',
            profile: '个人中心',
            logout: '登出',
            upload: '上传',
            loginMsg: '没账号？',
            username: '用户',
            password: '密码',
            signIn: '登入',
            signUp: '注册',
            register: {
                username: '用户或邮件地址',
                password: '不低于6个字符',
                show: '查看密码'
            }
        },
        right: {
            searchPlaceholder: '请输入内容',
            search: '搜索',
            hotTags: '热门标签',
            tags: {
                drama: '戏剧',
                horror: '恐怖',
                suspense: '焦虑',
                action: '动作',
                comedy: '搞笑',
            },
            hotSearch: '热门搜索'
        },
        popup: {
            p1: '不感兴趣',
            p2: '举报此视频',
            p3: '加入播放列表'
        }
    },
    channel: {
        fans: '粉丝',
        views: '观看',
        danmu: '弹幕',
        danmuPlaceholder: '发送一个弹幕',
        danmuSubmit: '发送',
        follow: '关注',
        following: '已关注',
        relational: '推荐',
        comments: '评论',
        commentPlaceholder: '添加评论 ...',
        commentSubmit: '提交',
        commentTip: '还没有人评论'
    },
    profile: {
        posts: '提交',
        followers: '关注',
        following: '粉丝',
        viewProfile: '查看主页'
    }
};
