import Vue from 'vue'
import Vuex from 'vuex';
import App from '@/App.vue'
import router from '@/router'
import fastclick from 'fastclick'
import store from '@/store/index'
import Message from '@/common/TMessage/TMessage'
import '@/common/TMessage/TMessage.css'
import '@/assets/styles/reset.css'
import '@/assets/styles/border.css'
import '@/assets/styles/global.css'
import VueI18n from 'vue-i18n'
import zh from '@/common/lang/zh.js'; // 导入中文语言文件
import en from '@/common/lang/en.js'; // 导入英文语言文件

Vue.use(VueI18n);

const messages = {
    zh, // zh: chinese
    en, // en: english
};

const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'zh', // 默认语言为中文
    messages,
});
Vue.config.productionTip = false
// 挂载到全局,TMessage
Vue.prototype.$message = Message
Vue.use(Vuex)
fastclick.attach(document.body)

new Vue({
    el: '#app',
    i18n,
    store,
    router,
    render: (h) => h(App),
})
